<template>
  <b-card
    class="blog-edit-wrapper"
  >
    <b-col md="12">
    	<span style="display: inline-block; font-size: 2rem; color: #000; padding-bottom: 0.5rem; margin-bottom: 1rem; border-bottom: 2px solid #000; width: 100%;">Historia serwisów</span>
    </b-col>
    <b-table
      :items="realizedservices"
      :fields="fieldsRealizedServices"
      primary-key="id"
      show-empty
      empty-text="Brak zrealizowanych serwisów"
      class="position-relative mt-2"
    >
      <!-- Column: Servicer -->
      <template #cell(servicer)="data">

        <div class="text-nowrap">
            {{data.item.servicer.firstname}} {{data.item.servicer.lastname}}
        </div>
      </template>
      
      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-realizedservice', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button variant="primary" class="mr-1" @click="deleteRealizedService(data.item.id)">
              <feather-icon icon="TrashIcon" />
            </b-button>
        </div>
      </template>

    </b-table>
    <b-col md="12">
    	<span style="display: inline-block; font-size: 2rem; color: #000; padding-bottom: 0.5rem; margin-bottom: 1rem; border-bottom: 2px solid #000; width: 100%;">Historia napraw</span>
    </b-col>
    <b-table
      :items="reparatures"
      :fields="fieldsReparatures"
      primary-key="id"
      show-empty
      empty-text="Brak napraw"
      class="position-relative mt-2"
    >
      <!-- Column: Servicer -->
      <template #cell(servicer)="data">

        <div class="text-nowrap">
            {{data.item.servicer.firstname}} {{data.item.servicer.lastname}}
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-reparatur', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button variant="primary" class="mr-1" @click="deleteReparatur(data.item.id)">
              <feather-icon icon="TrashIcon" />
            </b-button>
        </div>
      </template>

    </b-table>
    <b-col
      cols="12"
      class="mt-50"
    >
      <b-button
        :to="{ name: 'apps-client', params: { id: client_id } }"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
      >
        Wstecz
      </b-button>
    </b-col>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BTable, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    quillEditor,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      fieldsRealizedServices: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'service_id',
          label: 'Nazwa',
          sortable: true,
        },
        {
          key: 'service_date',
          label: 'Data serwisu',
          sortable: true,
        },
        {
          key: 'servicer',
          label: 'Serwisant',
          sortable: true,
        },
        {
          key: 'note',
          label: 'Notatka',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'akcje',
        },
      ],
      fieldsReparatures: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nazwa',
          sortable: true,
        },
        {
          key: 'reparatur_date',
          label: 'Data naprawy',
          sortable: true,
        },
        {
          key: 'servicer',
          label: 'Serwisant',
          sortable: true,
        },
        {
          key: 'note',
          label: 'Opis naprawy',
        },
        {
          key: 'actions',
          label: 'akcje',
        },
      ],
      realizedservices: [],
      reparatures: [],
      client_id: 0,
      filter_id: 0,
    }
  },
  created() {
    this.filter_id = this.$route.query.id_filter ?? 0
    this.client_id = this.$route.query.id_client ?? 0
    this.fetchRealizedServiceByClientId()
    this.fetchReparaturByClientId()
  },
  methods: {
    fetchRealizedServiceByClientId() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
	  axios.get(`${process.env.VUE_APP_API_URL}RealizedServiceByClientId/${this.client_id}/${this.filter_id}`, config).then(response => {
	    this.realizedservices = response.data
	  }).catch(error => {
	    console.log(error)
	  })
    },
    fetchReparaturByClientId() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
	  axios.get(`${process.env.VUE_APP_API_URL}ReparaturByClientId/${this.client_id}/${this.filter_id}`, config).then(response => {
	    this.reparatures = response.data
	  }).catch(error => {
	    console.log(error)
	  })
    },
    deleteRealizedService(id) {
      axios.delete(`${process.env.VUE_APP_API_URL}RealizedService/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        Swal.fire(
          'Pomyślnie usunięto naprawę',
          '',
          'success',
        )
	    this.fetchRealizedServiceByClientId()
      }).catch(() => {
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    },
    deleteReparatur(id) {
      axios.delete(`${process.env.VUE_APP_API_URL}Reparatur/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }).then(() => {
        Swal.fire(
          'Pomyślnie usunięto naprawę',
          '',
          'success',
        )
	    this.fetchReparaturByClientId()
      }).catch(() => {
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
